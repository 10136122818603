import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
    img: {
        position: "unset",
    },
    about: {
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%"
    },
    aboutContainer: {
        padding: "2rem"
    },
    aboutDescription: {
        margin: "2rem 0 0 0",
        "@media screen and (min-width:320px) and (max-width:479px)": {
            margin: "15px 0 0 0",
        },
    },
    marginTop: {
        margin: "5rem 0 0 0",
        "@media screen and (min-width:320px) and (max-width:960px)": {
            margin: "1rem 0 0 0",
        },
    },
    bodyTableMarginLeft: {
        margin: "0 1rem 0 1rem",
    },
    listMargin: {
        margin: "1rem 0 1rem 0",
    },
    headingTypography: {
        // color: "#406ddb",
        marginTop: "10px",
        marginLeft: "10px",
        fontWeight: "bold",
        "@media screen and (min-width:480px) and (max-width:540px)": {
            fontSize: "3rem",
        },
        "@media screen and (min-width:320px) and (max-width:479px)": {
            fontSize: "2rem",
        },
    },
    button: {
        marginTop: '10px',
        marginLeft: '10px',
        // color: '#406ddb',
        border: '4px solid #212121',
        "@media screen and (min-width:480px) and (max-width:540px)": {
            fontSize: "9px",
            border: '1px solid #212121',
        },
        "@media screen and (min-width:320px) and (max-width:479px)": {
            fontSize: "7px",
            border: '1px solid #212121',
        },
    },
    bodyText: {
        marginTop: '10px',
        marginLeft: '10px',
        // color: '#406ddb',
        fontSize: '22px',
        "@media screen and (min-width:480px) and (max-width:540px)": {
            margin: "1rem 0 1rem 0",
            fontSize: "13px",
        },
        "@media screen and (min-width:320px) and (max-width:479px)": {
            margin: "15px 0 15px 0",
            fontSize: "11px",
        },
    },
    bodyTableText: {
        marginTop: '10px',
        marginLeft: '10px',
        // color: '#406ddb',
        fontSize: '22px',
    },
    cardCarouselDiv: {
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        height: "8rem",
        width: "25rem",
        // paddingTop: "80px",
        textAlign: "center",
        background: "#FFC107",
        // background: "#52C0F5",
        color: "#FFF",
        // fontFamily: "sans-serif",
        fontSize: "15px",
        textTransform: "uppercase",
        borderRadius: "10px",
        boxSizing: "border-box",
        // margin: "2rem",
        "@media screen and (min-width:601px) and (max-width:800px)": {
            width: "20rem",
            height: "8rem",
            fontSize: "15px",
        },
        "@media screen and (min-width:541px) and (max-width:600px)": {
            width: "17rem",
            height: "7rem",
            fontSize: "13px",
        },
        "@media screen and (min-width:480px) and (max-width:540px)": {
            width: "15rem",
            height: "7rem",
            fontSize: "9px",
        },
        "@media screen and (min-width:320px) and (max-width:479px)": {
            width: "10rem",
            height: "7rem",
            fontSize: "9px",
        },
    },
    cardCarouselContainer: {
        position: "relative",
        zIndex: "0",
        // height: "100vh",
        height: "11rem",
        width: "52%",
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignItems: "middle",
        margin: "1rem",
        "@media screen and (min-width:1441px) and (max-width:1600px)": {
            width: "57%",
        },
        "@media screen and (min-width:1367px) and (max-width:1440px)": {
            width: "67%",
        },
        "@media screen and (min-width:1025px) and (max-width:1366px)": {
            width: "88%",
        },
        "@media screen and (min-width:961px) and (max-width:1024px)": {
            width: "93%",
        },
        "@media screen and (min-width:800px) and (max-width:960px)": {
            width: "93%",
            height: "8rem",
        },
        "@media screen and (min-width:541px) and (max-width:800px)": {
            width: "94%",
            height: "8rem",
        },
        "@media screen and (min-width:480px) and (max-width:540px)": {
            width: "94%",
            height: "7rem",
        },
        "@media screen and (min-width:320px) and (max-width:479px)": {
            width: "87%",
            height: "6rem",
        },
    },
    techStackCarouselContainer: {
        width:"155%",
        "@media screen and (min-width:1441px) and (max-width:1600px)": {
            width: "177%",
        },
        "@media screen and (min-width:1367px) and (max-width:1440px)": {
            width: "197%",
        },
        "@media screen and (min-width:1025px) and (max-width:1366px)": {
            width: "262%",
        },
        "@media screen and (min-width:961px) and (max-width:1024px)": {
            width: "285%",
        },
        "@media screen and (min-width:800px) and (max-width:960px)": {
            width: "98%",
        },
        "@media screen and (min-width:541px) and (max-width:800px)": {
            width: "94%",
        },
        "@media screen and (min-width:480px) and (max-width:540px)": {
            width: "94%",
        },
        "@media screen and (min-width:320px) and (max-width:479px)": {
            width: "87%",
        },
    },
    imageCarouses: {
        width: "100%",
        height: "100%"
    },
    rightAlign: {
        float: "right",
        "@media screen and (min-width:320px) and (max-width:960px)": {
            float: "unset"
        },
    },
    leftAlign: {
        float: "left",
        "@media screen and (min-width:320px) and (max-width:960px)": {
            float: "unset"
        },
    },
    techStackPaper: {
        marginBottom: "3rem",
    }

});