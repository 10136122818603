import './App.css';
import './frontend/reusables/styles/ColorPalette.css';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import Home from './frontend/components/home/Home';
import BackgroundParticles from './frontend/reusables/BackgroundParticles';
import About from './frontend/components/about/About';
import Showcase from './frontend/components/showcase/Showcase';
import Contact from './frontend/components/contact/Contact';
import PageNotFound from './frontend/components/error/PageNotFound';

function App() {
  return (
    <Router>
      <div className="App">
      {/* <BackgroundParticles/> */}
        <Switch>
          <Route path="/" exact component={Home} />
          {/* <Route path="/" exact component={Home} /> */}
          <Route path="/about" exact component={About} />
          <Route path="/showcase" exact component={Showcase} />
          <Route path="/contact" exact component={Contact} />
          <Route path="/404" component={PageNotFound} />
          <Redirect to="/404" />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
