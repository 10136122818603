import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import {Link} from 'react-router-dom';

export default function AlertDialog(props) {
    const { 
        open, 
        onClose,
        headerMessage,
        buttonsProperties,
    } = props;

    // const handleClose = () => {
    //     this.open = false;
    // };    

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {headerMessage}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {buttonsProperties.map(item =>(
                    <Button key={item.buttonText} onClick={onClose} component={Link} to={item.buttonLink} color="primary"> {item.buttonText} </Button>
                ))}
            </DialogActions>
        </Dialog>
    )
}