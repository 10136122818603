import sunriseCareer from '../../images/showcase/sunrise-carrer.png';
import eventBuzz from '../../images/showcase/eventbuzz.png';
import siddharthaAccount from '../../images/showcase/siddhartha-account.png';
import nicAsiaEkyc from '../../images/showcase/nic-ekyc.png';
import nabilCareer from '../../images/showcase/nabil-carrer.png';
import nicAccount from '../../images/showcase/nic-account.png';
import paradiseWebsite from '../../images/showcase/paradise-website.png';
import sunriseAccount from '../../images/showcase/sunrise-account.png';

export const showcaseData = [
    {
        headingTitle: "EventBuzz360 Website",
        subHeading: "An one stop portal for all the global event industries.",
        descriptionText: "A website to help with all the searching, contacting and advertising event business in single platform. A place where event suppliers meets event organizers for one fantastic event.",
        projectTableData: [
            { leftRow: "Date", rightRow: "March 2021" },
            { leftRow: "Role", rightRow: "Full stact web developer" },
            { leftRow: "With", rightRow: "Extraordinary Events" },
            { leftRow: "Using", rightRow: "ReactJS, Slim PHP, MySql" },
        ],
        projectLink: "https://eventbuzz360.com/",
        image: eventBuzz,
    },
    {
        headingTitle: "Sunrise Bank Job Application Portal",
        subHeading: "An online job application platform for Sunrise Bank Nepal.",
        descriptionText: "An vacancy module is made for Sunrise Bank Nepal to help with the process of job application processing made easy. Full Backend support with multiple users and multiple parameter to drive the form into various steps of validating, screening and acceptance.",
        projectTableData: [
            { leftRow: "Date", rightRow: "March 2019" },
            { leftRow: "Role", rightRow: "Code / Backend" },
            { leftRow: "With", rightRow: "Amnil Technologies" },
            { leftRow: "Using", rightRow: "Bootstrap 4, PHP-Codeigniter, MySql" },
        ],
        projectLink: "https://career.sunrisebank.com.np/",
        image: sunriseCareer,
    },
    {
        headingTitle: "Siddhartha Bank Online Account Opening",
        subHeading: "An sequence of online forms to open bank account online.",
        descriptionText: "A series of forms are made in order to help with the online account opening process in bank. Wide range of backend screening functionalities were added to help with the Maker, Checker and Reviewer to pass through various stages.",
        projectTableData: [
            { leftRow: "Date", rightRow: "December 2018" },
            { leftRow: "Role", rightRow: "Code / Backend" },
            { leftRow: "With", rightRow: "Amnil Technologies" },
            { leftRow: "Using", rightRow: "Bootstrap 4, PHP-Codeigniter, MySql" },
        ],
        projectLink: "https://openaccount.siddharthabank.com/",
        image: siddharthaAccount,
    },
    {
        headingTitle: "NIC Asia Bank Online eKYC Update",
        subHeading: "An eKYC update module made for NIC Asia Bank to assist with personal information update of customers.",
        descriptionText: "eKYC (Electronic Know Your Customer) forms was integrated on NIC Asia's existing website to help with the complience of Nepal's Government rules to update every details of cutomers. Various integration with the API of Bank's central system gives the power of easy and fast update of any customer's details.",
        projectTableData: [
            { leftRow: "Date", rightRow: "January 2019" },
            { leftRow: "Role", rightRow: "Code / Backend" },
            { leftRow: "With", rightRow: "Amnil Technologies" },
            { leftRow: "Using", rightRow: "Bootstrap 4, PHP-Codeigniter, MySql" },
        ],
        projectLink: "https://www.nicasiabank.com/ekyc",
        image: nicAsiaEkyc,
    },
    {
        headingTitle: "Nabil Investment Bank Job Application Portal",
        subHeading: "An online job application platform for Nabil Investment Bank Nepal.",
        descriptionText: "An vacancy module is made for Nabil Investment Bank Nepal to help with the process of job application processing made easy. Full Backend support with multiple users and multiple parameter to drive the form into various steps of validating, screening and acceptance.",
        projectTableData: [
            { leftRow: "Date", rightRow: "April 2019" },
            { leftRow: "Role", rightRow: "Code / Backend" },
            { leftRow: "With", rightRow: "Amnil Technologies" },
            { leftRow: "Using", rightRow: "Bootstrap 4, PHP-Codeigniter, MySql" },
        ],
        projectLink: "http://www.nabilinvest.com.np/career/",
        image: nabilCareer,
    },
    {
        headingTitle: "NIC Asia Bank Online Account Opening",
        subHeading: "An sequence of online forms to open bank account online.",
        descriptionText: "A series of forms are made in order to help with the online account opening process in bank. Wide range of backend screening functionalities were added to help with the Maker, Checker and Reviewer to pass through various stages.",
        projectTableData: [
            { leftRow: "Date", rightRow: "December 2018" },
            { leftRow: "Role", rightRow: "Code / Backend" },
            { leftRow: "With", rightRow: "Amnil Technologies" },
            { leftRow: "Using", rightRow: "Bootstrap 4, PHP-Codeigniter, MySql" },
        ],
        projectLink: "https://onlineaccount.nicasiabank.com/",
        image: nicAccount,
    },
    {
        headingTitle: "Paradise Group Nepal Website",
        subHeading: "A website made for paradise builders, Nepal.",
        descriptionText: "An simple website made for paradise builders group nepal. This website contains the information about the paradise construction, services, career and contact information.",
        projectTableData: [
            { leftRow: "Date", rightRow: "November 2017" },
            { leftRow: "Role", rightRow: "Full stack" },
            { leftRow: "With", rightRow: "Innox Technomania" },
            { leftRow: "Using", rightRow: "Bootstrap 4, PHP-Codeigniter, MySql" },
        ],
        projectLink: "http://www.paradisegroup.com.np/",
        image: paradiseWebsite,
    },
    {
        headingTitle: "Sunrise Bank Online Account Opening",
        subHeading: "An sequence of online forms to open bank account online.",
        descriptionText: "A series of forms are made in order to help with the online account opening process in bank. Wide range of backend screening functionalities were added to help with the Maker, Checker and Reviewer to pass through various stages.",
        projectTableData: [
            { leftRow: "Date", rightRow: "March 2019" },
            { leftRow: "Role", rightRow: "Code / Backend" },
            { leftRow: "With", rightRow: "Amnil Technologies" },
            { leftRow: "Using", rightRow: "Bootstrap 4, PHP-Codeigniter, MySql" },
        ],
        projectLink: "https://onlineaccount.sunrisebank.com.np/",
        image: sunriseAccount,
    },
    {
        headingTitle: "Innox Library System",
        subHeading: "A library system made to assist with all functionalities of library in any university",
        descriptionText: "A barcode system programmed to help with all the functionalities of any library systems in university. Deployed in various universities of Nepal for offline functionalities of application.",
        projectTableData: [
            { leftRow: "Date", rightRow: "January 2018" },
            { leftRow: "Role", rightRow: "Full Stack" },
            { leftRow: "With", rightRow: "Innox Technomania" },
            { leftRow: "Using", rightRow: "C#, .NET Framework, MS-SQL" },
        ],
        projectLink: "",
        image: "",
    },
    {
        headingTitle: "Digital Examination System",
        subHeading: "A entrance examination system developed for college entry in Nepal.",
        descriptionText: "An MCQ examination system developed to help for the college student to give entrance exam for specific 10+2 college.",
        projectTableData: [
            { leftRow: "Date", rightRow: "College Project" },
            { leftRow: "Role", rightRow: "Full Stack" },
            { leftRow: "With", rightRow: "KEC College" },
            { leftRow: "Using", rightRow: "JSP, Java, CSS, MySql" },
        ],
        projectLink: "",
        image: "",
    },
    {
        headingTitle: "Digital Steganography",
        subHeading: "A java based desktop application to hide one form of media in another form for secure messaging.",
        descriptionText: "A college mini project to aid with the secure messaging. Hides message in one media and crypting those media to secure message while transferring from one medium to another medium.",
        projectTableData: [
            { leftRow: "Date", rightRow: "College Mini Project" },
            { leftRow: "Role", rightRow: "Backend" },
            { leftRow: "With", rightRow: "KEC College" },
            { leftRow: "Using", rightRow: "Java" },
        ],
        projectLink: "",
        image: "",
    },
    {
        headingTitle: "Automatic Number Plate Scanner/Reader (ANPR)",
        subHeading: "A java based desktop application for reading number plates of moving vehicles.",
        descriptionText: "A college major project made to read number plates of moving vehicles. ",
        projectTableData: [
            { leftRow: "Date", rightRow: "College Major Project" },
            { leftRow: "Role", rightRow: "Backend" },
            { leftRow: "With", rightRow: "KEC College" },
            { leftRow: "Using", rightRow: "Java" },
        ],
        projectLink: "",
        image: "",
    },
];