import React from 'react';
import {
    makeStyles
} from "@material-ui/core";
import logo from '../../images/logo.png';

const useStyles = makeStyles(() => ({
    logo: {
        fontWeight: 600,
        color: "#fff",
        textAlign: "center",
    },
    logo1: {
        fontWeight: 600,
        color: "#000",
    },
    logoPNG: { 
        position: "unset", 
        height: "4em", 
        width: "4em" 
    },
}));

const HeaderText = (props) => {
    const classes = useStyles();
    return (
        // <Typography variant="h6" component="h1" className={ (props.calledFrom === "Navigation" ) ? classes.logo : classes.logo1}>
        <a href="/"><img alt="SamitLogo" className= {classes.logoPNG} src={logo}></img> </a>
        // </Typography>
    );
}

export default HeaderText;