import React from 'react';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import ShowcaseProject from './ShowcaseProject';
import { showcaseData } from './ShowcaseData';

const useStyles = makeStyles({
    headingTypography: {
        // color: "#406ddb",
        marginTop: "10px",
        marginLeft: "10px",
        fontWeight: "bold",
        "@media screen and (min-width:0px) and (max-width:600px)": {
            fontSize: "50px",
        },
        "@media screen and (min-width:601px) and (max-width:960px)": {
            fontSize: "65px",
        },
    },
});




function Showcase() {
    const classes = useStyles();
    return (
        <div className="showcase">
            <Header />
            <div className="showcaseContainer">
                <div>
                    <Typography variant="h1" className={classes.headingTypography}>Showcase</Typography>
                </div>
                {showcaseData.map(data => {
                    return (
                        <ShowcaseProject
                            key={data.headingTitle}
                            headingTitle={data.headingTitle}
                            subHeading={data.subHeading}
                            descriptionText={data.descriptionText}
                            projectTableData={data.projectTableData}
                            image={data.image}
                            projectLink={data.projectLink}
                        />
                    )
                })}
            </div>
            <Footer />

        </div>
    )
}

export default Showcase
