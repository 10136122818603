import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  headingTypography: {
    color: "#212121",
    marginTop: "10px",
    "@media screen and (min-width:480px) and (max-width:600px)": {
      fontSize: "70px",
      margin: "unset",
    },
    "@media screen and (min-width:414px) and (max-width:479px)": {
      fontSize: "50px",
      margin: "unset",
    },
    "@media (max-width:413px)": {
      fontSize: "40px",
      margin: "unset",
    }
  },
  commonTypography: {
    color: "#212121",
    marginTop: "10px",
    "@media screen and (min-width:480px) and (max-width:540px)": {
      fontSize: "20px",
    },
    "@media screen and (min-width:414px) and (max-width:479px)": {
      fontSize: "15px",
    },
    "@media (max-width:413px)": {
      fontSize: "13px",
      margin: "unset",
    }
  },
  button: {
    marginTop: '10px',
    color: '#212121',
    border: '4px solid #212121',
    "@media screen and (min-width:480px) and (max-width:540px)": {
      fontSize: "13px",
      border: '2px solid #212121',
    },
    "@media screen and (min-width:414px) and (max-width:479px)": {
      fontSize: "10px",
      border: '2px solid #212121',
    },
    "@media (max-width:413px)": {
      fontSize: "7px",
      border: '1px solid #212121',
    },
  },
  linkedinButton: {
    marginTop: '10px',
    color: '#fff',
    backgroundColor: '#0a66c2',
    "@media (max-width:480px)": {
      width: "75%",
    }
  },
  downloadCVButton: {
    marginTop: '10px',
    marginLeft: '5px',
    color: '#FFF',
    backgroundColor: '#FFA000',
    "@media (max-width:480px)": {
      width: "75%",
      marginLeft: "unset"
    }
  },
});