import React from 'react';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import Typography from "@material-ui/core/Typography";
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useStyles } from './styles/AboutStyle';

import ReactCardCarousel from "react-card-carousel";

import { myClientsAndAgencies } from './ClientsAndTechList.js';
import { myTechStack } from './ClientsAndTechList.js';

function About() {

    const classes = useStyles();
    return (

        <div className={classes.about}>
            <Header />
            <div className={classes.aboutContainer}>
                <div>
                    <Typography variant="h1" className={classes.headingTypography}>About</Typography>
                    <Button size="large" variant="outlined" className={classes.button} href="https://actac.com.au/" target="_blank">
                    I am currently employed on Australian Colleges as - Web Developer
                    </Button>
                </div>
                <div className={classes.aboutDescription}>
                    <Typography className={classes.bodyText} variant="body1" gutterBottom>
                        I’m an Australia based full-stack developer. Throughout my amlost 2 years of industry experience I have worked on wide variety of projects with multiple agencies, and used multiple programming languages.
                    </Typography>
                </div>
                <Paper elevation={5}>
                    <div>
                        <Grid
                            spacing={1}
                            direction="row"
                            container
                        >

                            <Grid item xs={12} sm={12} md={3}>
                                <div className={classes.marginTop}>
                                    <h2 className={clsx(classes.bodyTableMarginLeft,classes.paperHeading)}>My clients and agencies</h2>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={9}>
                                <div className={clsx(classes.cardCarouselContainer, classes.rightAlign)}>
                                    <ReactCardCarousel className={classes.test} autoplay={true} autoplay_speed={2500}>
                                        {myClientsAndAgencies.map((child, i) => {
                                            return (
                                                <div className={classes.cardCarouselDiv}>
                                                    <div>
                                                        <img className={classes.imageCarouses} src={child.src} alt={child.text} />
                                                    </div>
                                                    <div>
                                                        {child.text}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </ReactCardCarousel>
                                </div>

                            </Grid>

                        </Grid>
                    </div>
                </Paper>
                <div>
                    <Typography className={classes.bodyText} variant="body1" gutterBottom>
                        Always committed to giving the user the best possible experience and creating something everyone can be excited about. Nothing gives me greater satisfaction than working with the latest technology on a really great website, whether it’s a single page app or a bespoke social network.
                        </Typography>
                </div>
                <Paper elevation={5} className={classes.techStackPaper}>
                    <div>
                        <Grid
                            spacing={1}
                            direction="row"
                            container
                        >

                            <Grid item xs={12} sm={12} md={3}>
                                <div className={clsx(classes.cardCarouselContainer, classes.leftAlign, classes.techStackCarouselContainer)}>
                                    <ReactCardCarousel className={classes.test} autoplay={true} autoplay_speed={2500}>
                                        {myTechStack.map((child, i) => {
                                            return (
                                                <div className={classes.cardCarouselDiv}>
                                                    <div>
                                                        <img className={classes.imageCarouses} src={child.src} alt={child.text} />
                                                    </div>
                                                    <div>
                                                        {child.text}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </ReactCardCarousel>
                                </div>

                            </Grid>

                            <Grid item xs={12} sm={12} md={9}>
                                <div className={clsx(classes.marginTop,classes.rightAlign)}>
                                    <h2 className={classes.bodyTableMarginLeft}>Selected tech stack</h2>
                                </div>
                            </Grid>

                        </Grid>
                    </div>
                </Paper>
            </div>
            <Footer />
        </div>
    )
}

export default About
