import {Button, Link, MenuItem} from '@material-ui/core';
import { Link as RouterLink } from "react-router-dom";
import {useStyles} from './styles/HeaderStyle';
import Grid from '@material-ui/core/Grid';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

export  const headersData = [
    {
        label: "About",
        href: "/about",
    },
    {
        label: "Showcase",
        href: "/showcase",
    },
    {
        label: "Contact",
        href: "/contact",
    },
];

export function GetMenuButtons() {
    const {menuButton} = useStyles();
    return headersData.map(({ label, href }) => {
        return (
            <Button
                {...{
                    key: label,
                    color: "inherit",
                    to: href,
                    component: RouterLink,
                    className: menuButton,
                }}
            >
                {label}
            </Button>
        );
    });
}

export function GetMenuDrawerChoices() {
    const {menudrawerPaper} = useStyles();
    return headersData.map(({ label, href }) => {
        return (
            <Link
                {...{
                    component: RouterLink,
                    to: href,
                    color: "inherit",
                    key: label,

                }}
            >
                <MenuItem className={menudrawerPaper}>
                    <Grid
                        spacing={1}
                        direction="row"
                        container
                        alignItems="center"
                        justify="center"
                    >
                        <Grid item xs={11}>
                            <Typography variant="inherit">{label}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <ListItemIcon>
                                <ArrowForwardIcon style={{ fontSize: '1.5rem', color: "#fff" }} />
                            </ListItemIcon>
                        </Grid>
                    </Grid>



                </MenuItem>
                {/* <MenuItem className={menudrawerPaper}>{label}</MenuItem> */}
            </Link>
        );
    });
};


