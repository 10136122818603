import React from 'react';
import {Button, IconButton} from '@material-ui/core';
import { Link as RouterLink } from "react-router-dom";
import {useStyles} from './styles/HeaderStyle';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import HeaderText from './HeaderText';
import CloseIcon from '@material-ui/icons/Close';

export const topRightData = [
    // {
    //     label: "Sign in",
    //     href: "/signin",
    // },
    // {
    //     label: "Become a traveller",
    //     href: "/becomeatraveller",
    // },
];

export function GetTopRightMenuButtons(){
    const {menuButton,featuredButton} = useStyles();
    const proBtnClass = classNames(menuButton, featuredButton);
    return topRightData.map(({ label, href }) => {
        if (label === "Become a traveller") {
            return (
                <Button
                    {...{
                        key: label,
                        to: href,
                        component: RouterLink,
                        className: proBtnClass,
                    }}>
                    {label}
                </Button>
            );
        }
        else {
            return (
                <Button
                    {...{
                        key: label,
                        color: "inherit",
                        to: href,
                        component: RouterLink,
                        className: menuButton,
                    }}
                >
                    {label}
                </Button>
            );
        }
    });
};

export function GetDrawerTop(props){
    const {closeIcon} = useStyles();
    // const [state, setState] = useState({
    //     drawerOpen: false,
    // });
    // const handleCloseClick = useCallback(onCloseClick()},[])
    return (
        <>
            <Grid
                spacing={0}
                direction="row"
                container
                alignItems="center"
                justify="center"
            >
                <Grid item xs={6}>
                    <HeaderText calledFrom="Navigation" />
                </Grid>
                {/* <Grid item xs={false} sm={4} /> */}
                {/* <Grid item xs={6} sm={2} >
                    <Button variant="outlined" style={{ color: '#fff', borderColor: '#fff', }}>
                        Sign in
                    </Button>
                </Grid>
                <Grid item xs={6} sm={2} >
                    <Button variant="contained" color="primary">
                        Get Started
                    </Button>
                </Grid> */}
                <Grid item xs={6}>
                    <IconButton
                        className={closeIcon}
                        style={{ color: '#fff', fontSize: 100 }}
                        // onClick={() => {
                        //     setState((prevState) => ({ ...prevState, drawerOpen: false }));
                        // }}
                        onClick = {()=>props.onCloseClick()}
                    >
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </Grid>
        </>
    );
};