import React from 'react';
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import './styles/banner.css';
import Typed from "react-typed";
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GetAppIcon from '@material-ui/icons/GetApp';
import GlitchText from 'react-glitch-effect/core/GlitchText';

import { useStyles } from './styles/BannerStyle';

// import cv from '../../assets/downloads/Samit_Puri_CV.pdf';
import cv from '../../assets/downloads/Samit_Puri_Resume.pdf';


function BannerText() {
    const classes = useStyles();

    const onDownload = () => {
        const link = document.createElement("a");
        link.download = `Samit Puri CV`;
        link.href = cv;
        link.click();
    };

    return (
        <>
            <div className="parent-container">
                <div class="custom-shape-divider-top-1631949294">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
                    </svg>
                </div>
                <div>
                    <Typography variant="h1" className={classes.headingTypography}>i am samIT.</Typography>
                </div>
                <Typography variant="h5" className={classes.commonTypography}>
                    Full stack Web Developer -
                    <Typed
                        strings={[
                            " ReactJS",
                            " PHP",
                            " MySql",
                            " NodeJS(Basics)"
                        ]}
                        typeSpeed={200}
                        backSpeed={80}
                        loop
                    />
                </Typography>
                <Button size="large" variant="outlined" className={classes.button} href="https://actac.com.au/" target="_blank">
                    I am currently employed on Australian Colleges as - <GlitchText> web developer</GlitchText>
                </Button>
                <div>
                    <center>
                        <Button
                            target="_blank"
                            href="https://www.linkedin.com/in/samitpuri1610/"
                            className={classes.linkedinButton}
                            variant="contained"
                            endIcon={<LinkedInIcon />}
                        > Connect with me on</Button>
                        <Button
                            onClick={onDownload}
                            className={classes.downloadCVButton}
                            variant="contained"
                            endIcon={<GetAppIcon />}
                        > Download my CV</Button>
                    </center>
                </div>
                <div class="custom-shape-divider-bottom-1631949675">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
                    </svg>
                </div>
            </div>
        </>
    )
}

export default BannerText
