import React from 'react';
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import './styles/showcase.css';


const useStyles = makeStyles({
    headingTypography: {
        // color: "#406ddb",
        marginTop: "10px",
        marginLeft: "10px",
        fontWeight: "bold",
    },
    subHeadingTypography: {
        // color: "#406ddb",
        marginTop: "10px",
        marginLeft: "10px",
        fontWeight: "bold",
        "@media screen and (min-width:0px) and (max-width:600px)": {
            fontSize: "30px",
        },
        "@media screen and (min-width:601px) and (max-width:960px)": {
            fontSize: "44px",
        },
    },
    headingDescTypography: {
        // color: "#406ddb",
        marginTop: "10px",
        marginLeft: "10px",
        textAlign: "justify",
        "@media screen and (min-width:0px) and (max-width:600px)": {
            fontSize: "20px",
        },
        "@media screen and (min-width:601px) and (max-width:960px)": {
            fontSize: "25px",
        },
    },
    button: {
        margin: "1rem 0 1rem 0",
        border: '4px solid #212121',
        "@media screen and (min-width:0px) and (max-width:600px)": {
            border: '1px solid #212121',
            fontSize: "9px",
        },
        "@media screen and (min-width:601px) and (max-width:960px)": {
            border: '1px solid #212121',
            fontSize: "13px",
        },
    },
    bodyText: {
        marginTop: '10px',
        marginLeft: '10px',
        // color: '#406ddb',
        fontSize: '20px',
        "@media screen and (min-width:0px) and (max-width:600px)": {
            marginTop: "unset",
            textAlign: "justify",
            fontSize: "13px",
        },
        "@media screen and (min-width:601px) and (max-width:960px)": {
            marginTop: "unset",
            textAlign: "justify",
            fontSize: "17px",
        }
    },
    bodyTableText: {
        marginTop: '10px',
        marginLeft: '10px',
        // color: '#406ddb',
        fontSize: '22px',
    },

});

function ShowcaseProject(props) {
    const classes = useStyles();
    return (
        <div>
            <Paper elevation={5} className="showcase-paper">
                <div>
                    <Typography variant="h2" className={classes.subHeadingTypography}>{props.headingTitle}</Typography>
                </div>
                <div>
                    <Typography variant="h4" className={classes.headingDescTypography}>{props.subHeading}</Typography>
                </div>
                <div>
                    <Grid
                        spacing={1}
                        direction="row"
                        container

                    >
                        <Grid item xs={12} sm={5}>
                            <Paper elevation={5} className="showcase-inner-paper">
                                <div>
                                    <Grid
                                        spacing={1}
                                        direction="row"
                                        container
                                    >
                                        <Grid item xs={6}>
                                            <ul>
                                                {props.projectTableData.map(data => {
                                                    return (
                                                        <li key={data.leftRow}>{data.leftRow}</li>
                                                    )
                                                })}
                                            </ul>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <ul>
                                                {props.projectTableData.map(data => {
                                                    return (
                                                        <li key={data.rightRow}>{data.rightRow}</li>
                                                    )
                                                })}
                                            </ul>
                                        </Grid>
                                    </Grid>
                                    {(props.projectLink !== "") ? (
                                        <center>
                                            <Button target="_blank" href={props.projectLink} size="medium" variant="outlined" className={classes.button}>View project </Button>
                                        </center>
                                    ) : null}
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={7}>
                            <Typography className={classes.bodyText} variant="body1" gutterBottom>{props.descriptionText}</Typography>
                        </Grid>
                    </Grid>
                    {(props.image !== "") ? (
                        <div className="scroll-div">
                            <img className="scrollableImage" src={props.image} alt="project-portfolio" />
                        </div>
                    ) : null}

                </div>
            </Paper>
        </div>


    )
}

export default ShowcaseProject
