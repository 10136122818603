import React, { Component } from 'react';
import './styles/home.css';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import BannerText from '../banner/BannerText';

export class Home extends Component {
    render() {
        return (
            <div className="home">  
                <Header />    
                <BannerText />
                <Footer />   
            </div>
        )
    }
}

export default Home;
