import React from 'react';
import Particles from "react-tsparticles";
import { makeStyles } from '@material-ui/core/styles';
// import './styles/ColorPalette.css';

const useStyles = makeStyles({
    fullHeight: {
        height: '100%',
    },
    canvasParticles: {
        position: "fixed",
    }
});
function BackgroundParticles() {
    const classes = useStyles();
    return (
        <div className={classes.fullHeight}>
            <Particles
                className={classes.fullHeight}
                canvasClassName={classes.canvasParticles}
                id="tsparticles"
                // width="100%"
                // height="100px"
                options={{
                    background: {
                        color: {
                            value: "#FFF",
                        },
                        attachment: "fixed"
                    },

                    fpsLimit: 60,
                    interactivity: {
                        detectsOn: "parent",
                        events: {
                            onClick: {
                                enable: true,
                                mode: "bubble",
                            },
                            onHover: {
                                enable: true,
                                mode: "grab",
                            },
                            resize: true,
                        },
                        modes: {
                            bubble: {
                                distance: 400,
                                duration: 2,
                                opacity: 0.8,
                                size: 40,
                            },
                            push: {
                                quantity: 4,
                            },
                            repulse: {
                                distance: 200,
                                duration: 0.4,
                            },
                        },
                    },
                    particles: {
                        color: {
                            value: "#FFA000",
                        },
                        links: {
                            color: "#FFA000",
                            distance: 100,
                            enable: true,
                            opacity: 0.5,
                            width: 2,
                        },
                        collisions: {
                            enable: true,
                        },
                        move: {
                            direction: "none",
                            enable: true,
                            outMode: "bounce",
                            random: true,
                            speed: 3,
                            straight: false,
                        },
                        number: {
                            density: {
                                enable: true,
                                value_area: 800,
                            },
                            value: 80,
                        },
                        opacity: {
                            value: 0.5,
                        },
                        shape: {
                            type: "polygon",
                            polygon: {
                                "nb_sides": 5
                            },
                        },
                        size: {
                            random: true,
                            value: 5,
                        },
                    },
                    detectRetina: true,
                }}
            />
        </div>
    )
}

export default BackgroundParticles
