import {
    AppBar,
    Toolbar,
    IconButton,
    Drawer,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect } from "react";
// import { BrowserRouter as Router } from 'react-router-dom';
import HeaderText from './HeaderText';
import { GetMenuButtons, GetMenuDrawerChoices } from './HeadersData';
import {  GetDrawerTop } from './HeadersTopRightData';
// import { GetTopRightMenuButtons } from './HeadersTopRightData';
import { useStyles } from './styles/HeaderStyle';
import '../../reusables/styles/ColorPalette.css';

export default function Header() {
    const { header, toolbar, drawerContainer, drawerPaper, navigation } = useStyles();

    const [state, setState] = useState({
        mobileView: false,
        drawerOpen: false,
    });

    const { mobileView, drawerOpen } = state;

    useEffect(() => {
        const setResponsiveness = () => {
            return window.innerWidth < 900
                ? setState((prevState) => ({ ...prevState, mobileView: true }))
                : setState((prevState) => ({ ...prevState, mobileView: false }));
        };

        setResponsiveness();

        window.addEventListener("resize", () => setResponsiveness());
    }, []);

    const displayDesktop = () => {
        return (
            <Toolbar className={toolbar}>
                <div><HeaderText /></div>
                <div className={navigation}><GetMenuButtons /></div>
                {/* <div><GetTopRightMenuButtons /></div> */}
            </Toolbar>
        );
    };

    const displayMobile = () => {
        const handleDrawerOpen = () =>
            setState((prevState) => ({ ...prevState, drawerOpen: true }));
        const handleDrawerClose = () =>
            setState((prevState) => ({ ...prevState, drawerOpen: false }));

        return (
            <Toolbar>
                <IconButton
                    {...{
                        edge: "start",
                        "aria-label": "menu",
                        "aria-haspopup": "true",
                        onClick: handleDrawerOpen,
                    }}
                >
                    <MenuIcon />
                </IconButton>
                <Drawer
                    {...{
                        anchor: "left",
                        open: drawerOpen,
                        onClose: handleDrawerClose,
                        PaperProps: { className: drawerPaper },
                    }}
                >
                    <div className={drawerContainer}>
                        <GetDrawerTop onCloseClick={handleDrawerClose} />
                        <GetMenuDrawerChoices />
                    </div>
                </Drawer>
                <HeaderText />
            </Toolbar>
        );
    };


    return (
        // <Router>
            <header>
                <AppBar position="sticky" className={header}>
                    {mobileView ? displayMobile() : displayDesktop()}
                </AppBar>
            </header>
        // </Router>
    );
}