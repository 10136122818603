import eventbuzzlogo from '../../images/companies/eventbuzz.png';
import nicAsiaLogo from '../../images/companies/nic_asia.png';
import nabilBankLogo from '../../images/companies/nabil.png';
import amnilLogo from '../../images/companies/amnil.png';
import siddharthaLogo from '../../images/companies/siddhartha.png';
import innoxLogo from '../../images/companies/innox.png';
import sunriseLogo from '../../images/companies/sunrise_logo.png';

import phpLogo from '../../images/technologies/php.png';
import html5Logo from '../../images/technologies/html.png';
import javascriptLogo from '../../images/technologies/javascript.png';
import jqueryLogo from '../../images/technologies/jquery.png';
import reactLogo from '../../images/technologies/react.png';
import nodeLogo from '../../images/technologies/node.png';
import angularLogo from '../../images/technologies/angular.png';
import awsLogo from '../../images/technologies/aws.png';
import codeigniterLogo from '../../images/technologies/codeigniter.png';
import cssLogo from '../../images/technologies/css.png';
import gitLogo from '../../images/technologies/git.png';
import illustratorLogo from '../../images/technologies/illustrator.png';
import slimPhpLogo from '../../images/technologies/slimphp.png';

export const myClientsAndAgencies = [
    {src:eventbuzzlogo, text:'EventBuzz360 (Sydney)'},
    {src:nicAsiaLogo, text:'NIC ASIA Bank Ltd (Nepal)'},
    {src:nabilBankLogo, text:'NABIL Bank Ltd (Nepal)'},
    {src:amnilLogo, text:'Amnil Technologies Pvt Ltd (Nepal)'},
    {src:siddharthaLogo, text:'Siddhartha Bank Ltd (Nepal)'},
    {src:innoxLogo, text:'Innox Technomania Pvt Ltd (Nepal)'},
    {src:sunriseLogo, text:'Sunrise Bank Ltd (Nepal)'},
];

export const myTechStack = [
    {src: phpLogo, text:'PHP'},
    {src: html5Logo, text:'HTML'},
    {src: javascriptLogo, text:'Javascript'},
    {src: jqueryLogo, text:'Jquery'},
    {src: reactLogo, text:'React JS'},
    {src: nodeLogo, text:'Node JS'},
    {src: codeigniterLogo, text:'Codeigniter - PHP'},
    {src: cssLogo, text:'CSS'},
    {src: gitLogo, text:'GIT version control'},
    {src: slimPhpLogo, text:'SLIM PHP'},
    {src: illustratorLogo, text:'Adobe Illustrator (Basics)'},
    {src: angularLogo, text:'Angular JS (Basics)'},
    {src: awsLogo, text:'AWS (Basics)'},
]