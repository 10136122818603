import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(() => ({
    header: {
        backgroundColor: "#FFC107",
        paddingRight: "79px",
        paddingLeft: "118px",
        "@media (max-width: 900px)": {
            paddingLeft: 0,
        },
    },
    menuButton: {
        textTransform: "none",
        color: "#FFF",
        fontWeight: 700,
        size: "18px",
        marginLeft: "38px",
    },
    featuredButton: {
        background: 'black',
        color: 'white',
    },
    toolbar: {
        display: "flex",
        justifyContent: "space-between",
    },
    drawerContainer: {
        // backgroundColor: "#fc9696",
        backgroundColor: "#FFC107",
        color: "#FFF",
        padding: "10px 0px 10px 0px",
    },
    menudrawerPaper: {
        fontSize: "1rem",
        margin: "5px 0 0 0",
    },
    drawerPaper: {
        width: '100%',
    },
    closeIcon: {
        '& svg': {
            fontSize: 30,
            border: '3px solid #fff',
        },
        float:"right",
        
    },
    navigation:{
        float:"right",
    },
}));