import { makeStyles } from "@material-ui/core/styles";

export const styles = makeStyles({
    card: {
        // maxWidth: 300,
        padding: "2rem",
        margin: "auto",
        transition: "0.3s",
        boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
        "&:hover": {
            boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)"
        },
        "@media screen and (min-width:0px) and (max-width:959px)": {
            padding: "1rem",
        },
    },
    cardParent: {
        position: "relative",
        top: "25%",
        width: "80%",
        left: "10%",
        zIndex: "0",
        "@media screen and (min-width:0px) and (max-width:959px)": {
            top: "2%",
        },
    },
    media: {
        paddingTop: "56.25%",
        height: "100%"
    },
    contactHeading: {
        "@media screen and (min-width:0px) and (max-width:959px)": {
            margin: "unset",
        },
    },
    content: {
        textAlign: "left",
        marginBottom: "-20px"
        // padding: muiBaseTheme.spacing.unit * 3
    },
    divider: {
        // margin: `${muiBaseTheme.spacing.unit * 3}px 0`
    },
    responseForm: {
        margin: "10px 10px 10px 10px",
    },
    marginButtom: {
        marginBottom: "10px",
    },
    icon: {
        marginLeft: "20px"
    },
    facebookIcon: {
        color: ""
    },
    twitterIcon: {
        color: "#1da1f2",
    },
    linkedinIcon: {
        color: "#0077b5"
    },
    instagramIcon: {
        color: "#a133ac"
    },
    heading: {
        fontWeight: "bold"
    },
    subheading: {
        lineHeight: 1.8
    },
    avatar: {
        display: "inline-block",
        border: "2px solid white",
        "&:not(:first-of-type)": {
            // marginLeft: -muiBaseTheme.spacing.unit
        }
    },
    button: {
        backgroundColor: "#FFC107",
    },
    loading: {
        zIndex:"1",
    }
});