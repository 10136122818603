import React, { useState } from 'react';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import './styles/contact.css';
import { styles } from './styles/ContactStyle';

import Link from '@material-ui/core/Link';
import Card from "@material-ui/core/Card";
import Paper from '@material-ui/core/Paper';
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import TextField from "@material-ui/core/TextField";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import InstagramIcon from "@material-ui/icons/Instagram";
import Button from "@material-ui/core/Button";
import SendIcon from "@material-ui/icons/Send";
import contactImage from "../../images/contact.png";
import clsx from 'clsx';

import { contactSchema } from "./ContactValidationSchema";
// import { DisplayFormikState } from "../../reusables/DisplayFormikState";
import { Formik, Form } from 'formik';

import AlertDialog from '../../reusables/AlertDialog';

import { CircleLoading } from 'react-loadingg';


import emailjs from 'emailjs-com';

function Contact() {
    const classes = styles();

    const [loading, setLoading] = useState(false);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const [dialogHeaderText, setDialogHeaderText] = useState('');
    const [dialogButtonProperties, setDialogButtonProperties] = useState([]);

    const openDialog = (dialogHeaderMessage, buttonsProperties) => {
        setDialogIsOpen(true);
        setDialogHeaderText(dialogHeaderMessage);
        setDialogButtonProperties(buttonsProperties);
    }

    const closeDialog = () => {
        setDialogIsOpen(false);
    }

    const sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const sendEmail = async (paraName, paraEmail, paraMessage) => {
        // ev.preventDefault();

        console.log(paraName);
        console.log(paraEmail);
        console.log(paraMessage);

        const templateParams = {
            to: "caseysameet@gmail.com",
            subject: "Personal Website - New Message",
            fromName: paraName,
            fromEmail: paraEmail,
            message: paraMessage
        }

        setLoading(true);
        // await sleep(4000);
        // if (true) {
        //     const btnsProperties = [
        //         { buttonText: "OK", buttonLink: "/contact" },
        //     ];
        //     openDialog("Thanks for contacting, I will get back to you soon.", btnsProperties);
        //     setLoading(false);
        // }

        emailjs.send(
            'service_jbb15sm',
            'template_57fgb71',
            templateParams,
            'user_lSQEIjixrzy0xJH6JkPPF'
        ).then((result) => {
            if (result.text === "OK") {
                const btnsProperties = [
                    { buttonText: "OK", buttonLink: "/contact" },
                ];
                openDialog("Thanks for contacting, I will get back to you soon.", btnsProperties);
                setLoading(false);
            }
        }, (error) => {
            console.log(error.text);
        });
    }


    const buttonReplacementLoading = {
        margin: "unset",
    }
    return (

        <div className="contact">
            <Header />
            <div className={classes.cardParent}>
                <Card className={classes.card}>
                    <div>
                        <Grid spacing={1} direction="row" container>
                            <Grid item xs={12} sm={12} md={5}>
                                <div>
                                    <h1 className={classes.contactHeading}>Get in Touch</h1>
                                    <Divider className={classes.divider} light />
                                    <p>
                                        Lets make something awesome togther. <br />
                                    </p>
                                    <div>
                                        <LocationOnIcon /> Brisbane, QLD, Australia
                                    </div>
                                    <div>
                                        <PhoneIcon /> 0-424 xxx xxx (Please, use the form to contact me.)
                                    </div>
                                    <div>
                                        <EmailIcon /> xxxxxxxxxx@xxx.com
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={5}>
                                <div className={classes.responseForm}>
                                    {/* <form
                                        autoComplete="off"
                                        onSubmit={sendEmail}
                                    > */}
                                    <Formik
                                        initialValues={{
                                            name: name,
                                            email: email,
                                            message: message,
                                        }}
                                        // onSubmit={async values => {
                                        //     await new Promise(resolve => setTimeout(resolve, 500));
                                        //     alert(JSON.stringify(values, null, 2));
                                        //   }}
                                        // onSubmit={sendEmail}
                                        validationSchema={contactSchema}
                                        onSubmit={async (values, { resetForm }) => {
                                            // console.log(values);
                                            // console.log(values.name);
                                            try {
                                                sendEmail(values.name, values.email, values.message);
                                                resetForm({})
                                                // setTimeout(sendEmail, 0);
                                            }
                                            catch (e) {
                                                console.log(e);
                                            }

                                        }}
                                        enableReinitialize={true}
                                    >
                                        {props => {
                                            const {
                                                values,
                                                touched,
                                                errors,
                                                dirty,
                                                isSubmitting,
                                                handleChange,
                                                handleBlur,
                                                handleSubmit,
                                                handleReset
                                            } = props;
                                            return (
                                                <Form onSubmit={handleSubmit}>
                                                    <TextField
                                                        // value={name}
                                                        value={values.name}
                                                        name="name"
                                                        // onChange={e => setName(e.target.value)}
                                                        // onChange={handleChange}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            // setTimeout(sendEmail, 0);
                                                        }}
                                                        id="name"
                                                        label="Your Name"
                                                        variant="outlined"
                                                        fullWidth
                                                        className={classes.marginButtom}
                                                        error={touched.name && Boolean(errors.name)}
                                                        helperText={touched.name && errors.name}
                                                    />
                                                    <TextField
                                                        // value={email}
                                                        value={values.email}
                                                        name="email"
                                                        // onChange={e => setEmail(e.target.value)}
                                                        onChange={handleChange}
                                                        id="email"
                                                        label="Your Email"
                                                        variant="outlined"
                                                        fullWidth
                                                        className={classes.marginButtom}
                                                        error={touched.email && Boolean(errors.email)}
                                                        helperText={touched.email && errors.email}
                                                    />
                                                    <TextField
                                                        // value={message}
                                                        value={values.message}
                                                        name="message"
                                                        // onChange={e => setMessage(e.target.value)}
                                                        onChange={handleChange}
                                                        id="message"
                                                        label="Your Message"
                                                        multiline
                                                        rows={4}
                                                        variant="outlined"
                                                        fullWidth
                                                        className={classes.marginButtom}
                                                        error={touched.message && Boolean(errors.message)}
                                                        helperText={touched.message && errors.message}
                                                    />
                                                    {(loading === false) ? (
                                                        <Button
                                                            type="submit"
                                                            fullWidth
                                                            variant="contained"
                                                            className={classes.button}
                                                            endIcon={<SendIcon />}
                                                        > Send</Button>
                                                    ) : (
                                                        <center>
                                                            <Paper>
                                                                Please wait while we make a contact...
                                                                <CircleLoading color="#ffc107" style={buttonReplacementLoading} />
                                                            </Paper>
                                                        </center>
                                                    )}
                                                    {/* <DisplayFormikState {...props} /> */}
                                                </Form>
                                            )
                                        }}

                                    </Formik>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <CardContent className={classes.content}>
                        <center>
                            <Link target="_blank" href="https://www.facebook.com/samit.furious/"><FacebookIcon fontSize="small" className={clsx(classes.icon,classes.facebookIcon)} /></Link>
                            <Link target="_blank" href="https://twitter.com/samitpuri"><TwitterIcon fontSize="small" className={clsx(classes.icon,classes.twitterIcon)} /></Link>
                            <Link target="_blank" href="https://www.linkedin.com/in/samitpuri1610/"><LinkedInIcon fontSize="small" className={clsx(classes.icon,classes.linkedinIcon)} /></Link>
                            <Link target="_blank" href="https://www.instagram.com/samit_puri/"><InstagramIcon fontSize="small" className={clsx(classes.icon,classes.instagramIcon)} /></Link>
                        </center>
                    </CardContent>
                </Card>
            </div>
            <AlertDialog
                headerMessage={dialogHeaderText}
                open={dialogIsOpen}
                onClose={closeDialog}
                buttonsProperties={dialogButtonProperties}
            />
            <Footer />
        </div>
    )
}

export default Contact
